<template>
  <div class="register-custom">
    <header class="modal-header py25 px65 fs28 serif weight-700 cl-white bg-cl-main">
      {{ $t('Register') }}
      <i
        slot="close"
        class="modal-close icon-zamknij cl-bg-tertiary"
        @click="close"
      />
    </header>

    <div class="modal-content bg-cl-main-smoke p30 cl-secondary">
      <p class="visible-xs m0 mb15 cl-black h6 align-center">
        {{ $t('Register by e-mail') }}
      </p>
      <form @submit.prevent="register" novalidate>
        <base-input
          class="mb10"
          type="email"
          name="email"
          autocomplete="email"
          v-model="email"
          @blur="$v.email.$touch()"
          :placeholder="$t('E-mail address *')"
          :validations="[
            {
              condition: !$v.email.required && $v.email.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.email.email && $v.email.$error,
              text: $t('Please provide valid e-mail address.')
            }
          ]"
        />
        <div class="row mb10">
          <base-input
            class="col-xs-6"
            type="text"
            name="first-name"
            autocomplete="given-name"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
            :placeholder="$t('First name *')"
            :validations="[
              {
                condition: !$v.firstName.required && $v.firstName.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.firstName.minLength,
                text: $t('Name must have at least 2 letters.')
              },
              {
                condition: !$v.firstName.alpha && $v.firstName.$error,
                text: $t('Accepts only alphabet characters.')
              }
            ]"
          />
          <base-input
            class="col-xs-6"
            type="text"
            name="last-name"
            autocomplete="last-name"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
            :placeholder="$t('Last name *')"
            :validations="[
              {
                condition: !$v.lastName.required && $v.lastName.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.lastName.alpha && $v.lastName.$error,
                text: $t('Accepts only alphabet characters.')
              }
            ]"
          />
        </div>
        <base-input
          class=""
          type="password"
          name="password"
          ref="password"
          autocomplete="new-password"
          v-model="password"
          @blur="$v.password.$touch()"
          :placeholder="$t('Password *')"
          :validations="[
            {
              condition: !$v.password.required && $v.password.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.password.minLength && $v.password.$error,
              text: $t('Password must have at least 8 letters.')
            }
          ]"
        />
        <div data-testid="errorMessage" v-show="!$v.password.stringRegex && $v.password.$error" class="validation-message block cl-error h6 ml20">
          Hasło powinno zawierać przynajmniej 2 klasy znaków.
          <ul>
            <li>Małe litery: abc...</li>
            <li>Wielkie litery: ABC...</li>
            <li>Cyfry: 123...</li>
            <li>Znaki specjalne: !#$%&amp;()*+,-./</li>
          </ul>
          Przykład: test1234, Testtest, test*est
        </div>
        <base-input
          class="mb10 mt15"
          type="password"
          name="password-confirm"
          autocomplete="new-password"
          v-model="rPassword"
          @blur="$v.rPassword.$touch()"
          :placeholder="$t('Repeat password *')"
          :validations="[
            {
              condition: !$v.rPassword.required && $v.rPassword.$error,
              text: $t('Field is required.')
            },
            {
              condition: !$v.rPassword.sameAsPassword && $v.rPassword.$error,
              text: $t('Passwords must be identical.')
            }
          ]"
        />
        <div class="row m0 middle-xs">
          <base-checkbox
            class="terms-checkbox mb10 cl-black"
            id="register-terms"
            v-model="extension_attributes.consent_to_rules"
            @blur="$v.extension_attributes.consent_to_rules.$reset()"
            @change="$v.extension_attributes.consent_to_rules.$touch()"
            :validations="[{
              condition: !$v.extension_attributes.consent_to_rules.required && $v.extension_attributes.consent_to_rules.$error,
              text: $t('You must accept the terms and conditions.')
            }]"
          >
            Zapoznałem/am się i akceptuję treść <router-link @click.native="close" class="cl-main loginLink" :to="localizedRoute('/i/regulamin')" exact>
              Regulaminu Serwisu
            </router-link>.*
          </base-checkbox>
          <base-checkbox
            class="terms-checkbox mb10 cl-black"
            id="register-terms-2"
            v-model="extension_attributes.consent_to_email_commercials"
          >
            Zgadzam się na otrzymywanie drogą elektroniczną na podany adres email informacji handlowych dotyczących ciekawych produktów i usług Burda Media Polska Sp. z o. o. oraz jej Partnerów.
          </base-checkbox>
          <base-checkbox
            class="terms-checkbox mb10 cl-black"
            id="register-terms-3"
            v-model="extension_attributes.consent_to_phone_commercials"
          >
            Zgadzam się na używanie przez Burdę telekomunikacyjnych urządzeń końcowych (SMS) lub automatycznych systemów wywoławczych (telefon) w celu marketingu bezpośredniego ciekawych produktów i usług Burdy oraz jej Partnerów.
          </base-checkbox>
          <base-checkbox
            class="terms-checkbox mb10 cl-black"
            id="register-terms-4"
            v-model="extension_attributes.consent_to_newsletter"
          >
            Zgadzam się na otrzymywanie newslettera redakcyjnego z najnowszymi artykułami i wiadomościami z naszego serwisu.
          </base-checkbox>
          <p class="h6 cl-black mt0 mb5 w-100">
            * Pola wymagane
          </p>
          <p class="h6 mt0 mb5 cl-black">
            <span class="weight-900 cl-main">Administrator danych:</span> Burda Media Polska Sp. z o. o., Marynarska 15, Warszawa.
          </p>
          <p class="h6 mt0 mb5 cl-black">
            <span class="weight-900 cl-main">Cel przetwarzania danych:</span> Świadczenie usług za pośrednictwem Serwisu a w razie wyrażenia odrębnej zgody – wysyłanie newslettera lub marketing bezpośredni.
          </p>
          <p class="h6 mt0 mb5 cl-black">
            <span class="weight-900 cl-main">Podstawy prawne przetwarzania danych:</span> Art. 6 ust. 1 lit b) RODO - niezbędność danych do świadczenia usług za pomocą Serwisu, art. 6 ust. 1 lit c) RODO – niezbędność danych do wypełnienia obowiązku prawnego, art. 6 ust. 1 lit f) RODO - prawnie uzasadnione interesy administratora; Marketing elektroniczny - dobrowolna zgoda.
          </p>
          <p class="h6 mt0 mb5 cl-black">
            <span class="weight-900 cl-main">Odbiorcy danych:</span> Podmioty przetwarzające dane na zlecenie administratora, nasi Zaufani Partnerzy oraz podmioty uprawnione do uzyskania danych na podstawie obowiązującego prawa.
          </p>
          <p class="h6 mt0 mb5 cl-black">
            <span class="weight-900 cl-main">Prawa osoby, której dane dotyczą:</span> Prawo żądania sprostowania, usunięcia lub ograniczenia przetwarzania danych; prawo wniesienia skargi do organu nadzorczego; prawo do wycofania zgody; inne prawa, o których mowa w <router-link @click.native="close" class="cl-main loginLink" :to="localizedRoute('/i/polityka-prywatnosci')" exact>
              „Polityce Prywatności Serwisów”
            </router-link>.
          </p>
          <p class="h6 mt0 mb15 cl-black">
            <span class="weight-900 cl-main">Szczegółowe informacje o zasadach przetwarzania danych znajdziesz w</span> <router-link @click.native="close" class="cl-main loginLink" :to="localizedRoute('/i/polityka-prywatnosci')" exact>
              „Polityce Prywatności”
            </router-link>
          </p>
          <button-full :disabled="$v.$invalid" class="register-btn mb10 w-100 fs16" type="submit">
            {{ $t('Register an account') }}
          </button-full>
        </div>
        <div class="center-xs">
          <span>
            {{ $t('or') }}
            <a href="#" class="loginLink" @click.prevent="switchElem">
              {{ $t('login to your account') }}
            </a>
          </span>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Register from '@vue-storefront/core/compatibility/components/blocks/Auth/Register'
import ButtonFull from 'theme/components/theme/ButtonFull.vue'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import i18n from '@vue-storefront/i18n'
import { Logger } from '@vue-storefront/core/lib/logger'
import { registerModule } from '@vue-storefront/core/lib/modules'
import { GoogleRecaptcha } from 'src/modules/vsf-recaptcha'
import rootStore from '@vue-storefront/core/store'

const symClasses = [
  '[A-Z]',
  '[a-z]',
  '[0-9]',
  '(' +
    "!\"#&'()*+,-./"
      .split('')
      .map((char) => '.()/*+'.indexOf(char) !== -1 ? '\\' + char : char)// escape special symbols
      .join('|') +
  ')'
]
const reg = symClasses.map(
  (cls) =>
    new RegExp('^' + ['(?=', cls, ')'].join('.*') + '.*$')
);
const stringRegex = value => {
  // special characters validation
  return reg.filter(r => r.test(value)).length >= 2
}

export default {
  data () {
    return {
      extension_attributes: {
        consent_to_rules: false,
        consent_to_email_commercials: false,
        consent_to_phone_commercials: false,
        consent_to_newsletter: false
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      minLength: minLength(2),
      required
    },
    lastName: {
      required
    },
    password: {
      stringRegex,
      minLength: minLength(8),
      required
    },
    rPassword: {
      required,
      sameAsPassword: sameAs('password')
    },
    extension_attributes: {
      consent_to_rules: {
        sameAs: sameAs(() => true)
      }
    }
  },
  mixins: [Register],
  components: {
    ButtonFull,
    BaseCheckbox,
    BaseInput
  },
  beforeCreate () {
    registerModule(GoogleRecaptcha)
  },
  mounted () {
    this.showRecaptchaBadge()
  },
  beforeDestroy () {
    this.hideRecaptchaBadge()
  },
  methods: {
    showRecaptchaBadge () {
      if (document.getElementsByClassName('grecaptcha-badge').length) document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'visible'
    },
    hideRecaptchaBadge () {
      document.getElementsByClassName('grecaptcha-badge')[0].style.visibility = 'hidden'
    },
    callRegister () {
      this.$bus.$emit('notification-progress-start', i18n.t('Registering the account ...'))
      this.$store.dispatch('user/register', { email: this.email, password: this.password, firstname: this.firstName, lastname: this.lastName, extension_attributes: this.extension_attributes, gToken: this.$store.state.googleRecaptcha.google_generated_token }).then((result) => {
        Logger.debug(result, 'user')()
        this.$bus.$emit('notification-progress-stop')
        if (result.code !== 200) {
          this.onFailure(result)
          // If error includes a word 'password', focus on a corresponding field
          if (result.result.includes('password')) {
            this.$refs['password'].setFocus('password')
            this.password = ''
            this.rPassword = ''
          }
        } else {
          this.$store.dispatch('user/login', { username: this.email, password: this.password })
          this.onSuccess()
          this.close()
        }
      }).catch(err => {
        this.onFailure({ result: 'Unexpected authorization error. Check your Network conection.' })
        this.$bus.$emit('notification-progress-stop')
        Logger.error(err, 'user')()
      })
    },
    async register () {
      try {
        if (this.$v.$invalid) {
          this.$v.$touch()
          this.$store.dispatch('notification/spawnNotification', {
            type: 'error',
            message: this.$t('Please fix the validation errors'),
            action1: { label: this.$t('OK') }
          })
          return
        }
        await this.$store.dispatch('googleRecaptcha/isVerifiedCaptcha')
        if (this.$store.state.googleRecaptcha.google_generated_token) {
          this.callRegister()
        }
      } catch (error) { 
        rootStore.dispatch('notification/spawnNotification', {
          type: 'error',
          message: error,
          action1: { label: i18n.t('OK') }
        })
      }
    },
    onSuccess () {
      Vue.gtm.trackEvent({
        event: 'usercomevent',
        eventCategory: 'user',
        eventAction: 'signup',
        email: this.email,
        firstName: this.firstName,
        lastName: this.lastName,
        extension_attributes: this.extension_attributes
      })
      Vue.gtm.trackEvent({
        event: 'createNewAccount',
        eventCategory: 'Engagement',
        eventAction: 'Register'
      })
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: this.$t('You are logged in!'),
        action1: { label: this.$t('OK') }
      })
    },
    onFailure (result) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message: this.$t(result.result),
        action1: { label: this.$t('OK') }
      })
    }
  }
}
</script>

<style lang="scss">
  .register-custom {
    .main-label:before {
      top: -3px !important;
    }
    input:checked + label:after {
      top: 5px !important;
    }
    input {
      border-color: white;
    }
  }
</style>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$main: color(main);

  .fs28 {
    font-size: 28px;
  }
  .terms-checkbox {
    font-size: 12px;
    line-height: 16px;
  }
  .register-btn {
    @media (max-width: 767px) {
      padding: 10px 40px;
      margin-bottom: 60px;
    }
  }
  .loginLink {
    color: $main;
    &:hover {
      text-decoration: underline;
    }
  }
  .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      display: none;
    }
  }
  .modal-close{
    cursor: pointer;
  }
  .modal-content {
    @media (max-width: 400px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
</style>

<style>
/* recaptcha badge */
.grecaptcha-badge {
  z-index: 10;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: -2px !important;
  bottom: 20px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}
</style>
